$(function () {
    loadFont(
        'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Noto+Sans+TC:100,300,400,500,700'
    )

    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // $('.carousel').carousel({
    //     interval: 5000
    // });

    $("body").on("click", "#menuBtn", function (e) {
        $(this).toggleClass("open");
        $("body").toggleClass("hidebar");

        $("#header").toggleClass("open");
        $("#mainmenu").toggleClass("show");
        e.preventDefault();
    })

    // 點外面關閉 main-toggle-nav
    $(document).on('touchend click', function (e) {
        const toogleNav = $('#main-toggle-nav');

        if(toogleNav.hasClass("show")){
            if (!toogleNav.is(e.target) && toogleNav.has(e.target).length === 0) {
                $("#main-toggle-nav").removeClass("show");
                $(".rect-menu").attr("aria-expanded","false");
            }
        }
    });

    // 按esc可以關閉 main-toggle-nav
    $(document).on("keydown", function(e) {
        // ESCAPE key pressed
        if (e.keyCode == 27) {
            if($('#main-toggle-nav').hasClass("show")){
                $("#main-toggle-nav").removeClass("show");
                $(".rect-menu").attr("aria-expanded","false");
            }
        }
    });

    $("#main-toggle-nav a:last").on("focusout", function (event) {
        $("#main-toggle-nav").removeClass("show");
        $(".rect-menu").attr("aria-expanded","false");
    });


    $(".hassub").on("click", ".mainmenu-link", function (e) {
        if ($(window).width() < 992) {
            $(this).parents(".hassub").toggleClass("open");

            e.preventDefault();
        }
    })

    $("body").on("click", ".mobile-close", function (e) {
        $("#menuBtn").toggleClass("open");
        $("body").toggleClass("hidebar");

        $("#header").toggleClass("open");
        $("#mainmenu").toggleClass("show");

        e.preventDefault();
    })

    $("body").on("click", ".gotop", function (e) {
        if (isSafari) {
            $("body, html").animate({
                scrollTop: 0
            }, 600);
        } else {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        }

        e.preventDefault();
    })

    $('img.svgimg').each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');
    });
    if ($('.select-wrap').length > 0) {
        $('.select-wrap').on('click', function () {
            $(this).toggleClass('active')
            if ($('.select-wrap').hasClass('active')) {
                $('.member-filter-wrap').addClass('show')
            } else {
                $('.member-filter-wrap').removeClass('show')

            }
        })
    }
    if ($('#check-responsibility').length > 0) {
        $('#check-responsibility').on('change', function () {
            if ($(this).prop('checked')) {
                $('#download-group .btn').removeClass('cannot-use')
            } else {
                $('#download-group .btn').addClass('cannot-use')
            }
        })
    }
    if ($('.score-item').length > 0) {
  
    }
    if ($('.member-wrap .form-item.file').length > 0) {
        $('.member-wrap .form-item.file input').on('change', function (e) {
            $(this).prev().after(`<div class='label-mask'></div>`)
            setTimeout(() => {
                $(".label-mask").animate({
                  opacity: 0
                }, 2000, function(){
                  $(this).remove();
                })
              }, 100);
            $(this).closest('.form-item.file').after(`<div class='d-flex mb-3'> <a href=${URL.createObjectURL(e.target.files[0])} target='_blank'>${e.target.files[0].name}</a><span class='ps-3 cursor-pointer' onclick='removeFileFun(this)'>x</span></div>`);
            $(this).closest('.form-item.file').removeClass('error')
            $(this).closest('.form-item.file').find('.help-block').remove()
        })
     
    }
    if ($('.member-wrap .form-item.upload-img').length > 0) {
        $('.member-wrap .form-item.upload-img input').on('change', function (e) {
 
            if (e.target.files.length > 0) {
                const file = e.target.files;

                let newHtml = ``
                newHtml += `<div class='position-relative' onclick='removeself(event)'><img class='w-100' src='' id='upload-company-logo' /></div>`
                $(this).closest('.upload-img').find('.unUpload').addClass('invisible')
                $(this).closest('.upload-img').find('.img-group').html(newHtml)
                const fileReader = new FileReader();
                const preview = document.getElementById('upload-company-logo');
                fileReader.onload = event => {
                    preview.setAttribute('src', event.target.result);
                }
                fileReader.readAsDataURL(file[0]);
            }
        })
    }
    if ($(".subscribe-wrap").length>0) {
        $(".subscribe-wrap input").not("[type=submit]").jqBootstrapValidation();
    }

    if ($("#new_user").length>0) {
        $("#new_user").find("input").not("[type=submit]").jqBootstrapValidation();
    }
    if($("#award-work-contact-form").length > 0){
        $("#award-work-contact-form").find("input,select,textarea").not("[type=submit]").jqBootstrapValidation();
    }

    if ($("#edit_user").length>0) {
        $("#edit_user").find("input").not("[type=submit]").jqBootstrapValidation();
    }

    $(".togglePw").on("click", function(){
        var btn = $(this);

        if(btn.hasClass("close")){
            btn.removeClass("close");
            btn.prev("input").attr("type","text");
            btn.find(".eye-close").addClass('d-none');
            btn.find(".eye-open").removeClass('d-none');
        }else{
            btn.addClass("close");
            btn.prev("input").attr("type","password");
            btn.find(".eye-close").removeClass('d-none');
            btn.find(".eye-open").addClass('d-none');
        }
    })
})

var timeout;
document.addEventListener("DOMContentLoaded", function (event) {
    let lastScrollTop = 0;
    const header = document.getElementById("header");
    if(header){
        window.onscroll = () => {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                let curScrollTop = window.scrollY;
                if (lastScrollTop < curScrollTop) {
                    // console.log('down');
                    header.classList.add("up");
                } else {
                    // console.log('up');
                    header.classList.remove("up");
                }

                lastScrollTop = curScrollTop;
                gotopScroll();
            }, 50);
        };
    }
});

function loadFont(url) {
    // the 'fetch' equivalent has caching issues
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
            let css = xhr.responseText
            css = css.replace(/}/g, 'font-display: swap; }')

            const head = document.getElementsByTagName('head')[0]
            const style = document.createElement('style')
            style.appendChild(document.createTextNode(css))
            head.appendChild(style)
        }
    }
    xhr.send()
}

function gotopScroll() {
    const gotopBtn = document.getElementById("bottom-nav");

    if (window.scrollY > 100) {
        gotopBtn.classList.add("show");
    } else {
        gotopBtn.classList.remove("show");
    }

    let footerElement = document.getElementById('footer');
    let footerElementRect = footerElement.getBoundingClientRect();
    let gotopPositionBottom = gotopBtn.offsetTop + gotopBtn.offsetHeight;
    if (footerElementRect.y < gotopPositionBottom) {
        let diffheight = gotopPositionBottom - footerElementRect.y;
        let style = window.getComputedStyle(gotopBtn);
        let addBottom = parseInt(style.getPropertyValue('bottom')) + diffheight + 37;
        gotopBtn.style.bottom = addBottom + 'px';
        gotopBtn.classList.add("icon-white");
    } else {
        gotopBtn.style.bottom = '';
        gotopBtn.classList.remove("icon-white");
    }
}

