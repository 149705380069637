import $ from 'jquery';

document.addEventListener('DOMContentLoaded', function() {
    if($(".slider").length > 0){
        $('.slider').each(function(){
            let slideItem = $(this);
            slideItem.owlCarousel({
                loop: slideItem.find(".slider-image").length > 5 ? true : false,
                margin: 0,
                nav: false,
                dots: false,
                autoplay: false,
                autoplayTimeout: 5000,
                responsive:{
                    0:{
                        items: 2
                    },
                    1200:{
                        items: 5
                    }
                }
            })

            let $progressBar = slideItem.next('.slide-progress');
            slideItem.on('changed.owl.carousel', function(event) {
                let total = event.item.count;
                let $progressIndex = event.item.index - event.page.size;
                let calc = ($progressIndex / total) * 100;
                // if(calc == 0){
                //     calc = 20;
                // }

                $progressBar
                .css('background-size', calc + '% 100%')
                .attr('aria-valuenow', calc );

            })
        })
    } // end if slider

    if($(".jury-list").length > 0){
        $('.jury-list').each(function(){
            let jurySlide = $(this);

            if(jurySlide.find(".jury-list-slide").length > 1){
                // let $progressBar2 = $("#" + jurySlide.data("progress"));
                // jurySlide.on('changed.owl.carousel', function(event) {
                //     let total = event.item.count;
                //     let $progressIndex = event.item.index - event.page.size;
                //     let calc = ($progressIndex / total) * 100;

                //     $progressBar2
                //     .css('background-size', calc + '% 100%')
                //     .attr('aria-valuenow', calc );

                // })
            
                jurySlide.owlCarousel({
                    nav: true,
                    loop: true,
                    dots: false,
                    autoWidth: false,
                    items: 1
                })
            }
        })
    } // end jury-list
    
    if($("#relation-project").length > 0){
        $("#relation-project .owl-carousel").owlCarousel({
            nav: false,
            loop: false,
            dots: false,
            margin: 20,
            autoWidth: false,
            autoplay: false,
            autoplayTimeout: 5000,
            responsive:{
                0:{
                    items: 1,
                    autoplay: false,
                    autoplayTimeout: 5000,
                },
                768:{
                    items: 3,
                    autoplay: false
                }
            }
        })
    } // end #relation-project

    if($(".post-slide-list").length > 0){
        $(".post-slide-list").owlCarousel({
            nav: false,
            loop: false,
            dots: false,
            margin: 20,
            autoWidth: true,
            autoplay: false,
            autoplayTimeout: 5000,
            onInitialized: function (event) {
                const carouselID = $(".post-slide-list");
                carouselID.find('.owl-item').attr('aria-selected','false');
                carouselID.find('.owl-item.active').attr('aria-selected','true');
                
                // 鍵盤控制
                $(document).on('keydown', function(e){
                    var $focusedElement = $(document.activeElement),
                        singleOwl = $(".post-slide-list").data("owl.carousel"),
                        type = e.which == 39? 'next': null,
                        type = e.which == 37? 'prev': type,
                        type = e.which == 13? 'enter':type;
                    if($focusedElement.hasClass("post-slide-link")){
                        if (type == 'next') {
                            singleOwl.next();
                        } else if (type == 'prev') {
                            singleOwl.prev();
                        }
    
                    } else if (type == 'enter') {
                        if ($focusedElement.hasClass('owl-next')) {
                            singleOwl.next();
                        } else if ($focusedElement.hasClass('owl-prev')) {
                            singleOwl.prev();
                        }
                    }
                });
            }
        })

        $(".slide-btn").on("click", function(e){
            var action = $(this).data("action");

            if(action == "prev"){
                $(".post-slide-list").trigger('prev.owl.carousel');
            }else if(action == "next"){
                $(".post-slide-list").trigger('next.owl.carousel');
            }
        })
    } 

    if($(".full-slider").length > 0){
        $(".full-slider").owlCarousel({
            nav: false,
            loop: true,
            dots: false,
            margin: 20,
            autoWidth: true,
            center: true,
            autoplay: true,
            autoplayTimeout: 5000
        })
    }

    if($(".about-history-hor").length > 0){
        $(".about-history-hor").owlCarousel({
            nav: true,
            loop: false,
            autoWidth: false,
            dots: false,
            items: 1,
            autoplay: false,
            autoplayTimeout: 10000,
            responsive:{
                768:{
                    items: 2,
                    nav: false
                },
                991:{
                    items: 3,
                    nav: false
                },
                1200:{
                    items: 4,
                    nav: false
                }
            }
        })

        if($(window).width() <= 767){
            $(".about-history-hor").trigger('remove.owl.carousel',[$(".about-history-hor").find(".history-item").length - 1]).trigger('refresh.owl.carousel');
        }

        $(window).on("resize", function(){
            if($(window).width() <= 767){
                if($(".about-history-hor").find(".history-item.last").length > 0){
                    $(".about-history-hor").trigger('remove.owl.carousel',[$(".about-history-hor").find(".history-item").length - 1]).trigger('refresh.owl.carousel');
                }
            }else{
                if($(".about-history-hor").find(".history-item.last").length == 0){
                    $(".about-history-hor").trigger('add.owl.carousel','<div class=\"history-item last\"></div>').trigger('refresh.owl.carousel');
                }
            }
        })
    }

    if($(".grid-gallery-wrap").length > 0){
        let preloaded = 0
        let gridGalleryWrap = document.querySelector(".grid-gallery-wrap");
        let totalImages = Array.from(gridGalleryWrap.querySelectorAll('img'))

        for (let i = 0, l = totalImages.length; i < l; i++) {
            var tempImage = new Image()

            tempImage.addEventListener(
                'load',
                function () {
                    preloaded++
                    if (preloaded === l) {
                        gridGalleryWrap.classList.remove("d-none");

                        $(window).on('resize', updateCarousel);
                        updateCarousel();
                    }
                },
                true
            )

            tempImage.src = totalImages[i].src
        }
    }
});

let hasCarousel = false;
let carousel;
let updateCarousel = function () {
    let el = $(".grid-gallery-list.owl-carousel");
    let gridWrap = "grid-gallery2";
    let wrapper;
    let reInit = true;

    let carouselOptions = {
        nav: false,
        loop: false,
        dots: false,
        margin: 10,
        items: 1,
        autoWidth: false,
        autoplay: false,
        autoplayTimeout: 0,
        autoplaySpeed: 1000,
        responsive:{
            768:{
                items: 1
            },
            991:{
                items: 2
            },
            1200:{
                items: 1
            }
        },
        onInitialized: function (event) {
            const carouselID = $("#grid-gallery-list");
            carouselID.find('.owl-item').attr('aria-selected','false');
            carouselID.find('.owl-item.active').attr('aria-selected','true');
            
            // 鍵盤控制
            $(document).on('keydown', function(e){
                var $focusedElement = $(document.activeElement),
                    singleOwl = $("#grid-gallery-list").data("owl.carousel"),
                    type = e.which == 39? 'next': null,
                    type = e.which == 37? 'prev': type,
                    type = e.which == 13? 'enter':type;
                if($focusedElement.hasClass("gallery-item-link")){
                    if (type == 'next') {
                        singleOwl.next();
                    } else if (type == 'prev') {
                        singleOwl.prev();
                    }

                } else if (type == 'enter') {
                    if ($focusedElement.hasClass('owl-next')) {
                        singleOwl.next();
                    } else if ($focusedElement.hasClass('owl-prev')) {
                        singleOwl.prev();
                    }
                }
            });
        }
    };

    if(hasCarousel){
        carousel.trigger('destroy.owl.carousel');
    }

    if(el.hasClass("grid")){
        el.find("." + gridWrap).each(function(index){
            let item = $(this).find(".gallery-item");
            item.appendTo(el);
            $(this).detach();
        });

        $(".grid-gallery-list").removeClass("grid");
    }

    if($(window).width() >= 1200){
        el.addClass("grid");
        el.find(".gallery-item").each(function(index){
            if(index % 5 == 0){
                wrapper = $('<div class="' + gridWrap + '"></div>').appendTo(el);
            }
            let item = $(this);
            item.appendTo(wrapper);
        })
    }else{

    }

    if (reInit) {
        carousel = el.owlCarousel(carouselOptions);
        hasCarousel = true;
    }
}